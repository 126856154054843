//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
