//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  auth: false,
  head: () => ({
    title: 'Activiteiten en trainingen',
  }),
};
